import React from "react";
import { Col } from "react-bootstrap";
import * as S from "./footer.styles";

const Footer = () => {
  return (
    <S.MainContainer>
      <S.HeadSection>
        <h1 className="py-2">Our Address</h1>
      </S.HeadSection>
      <S.AddressContaier>
        <S.AddressWrapper>
          <h4>Contact for Counseling and Prayer</h4>
          <p>
            Pastor: D. Ravi Kumar
            <br />
            Phone: 7204074515 / 9845754515
            <br />
            WhatsApp: 7204074515 / 9845754515
            <br />
            Email: pgf.prayerrequests@gmail.com
          </p>
        </S.AddressWrapper>
        <S.AddressWrapper>
          <h4>Service Timings</h4>
          <p>
            <strong>Sunday Service:</strong> 10:00 am to 12:30 pm
          </p>
          <h5>Online Prayer (Zoom):</h5>
          <p>
            <strong>Every Wednesday : </strong> 8:00 pm to 9:00 pm
          </p>
          <p>
            <strong>Every Saturday : </strong> 8:00 pm to 9:00 pm
          </p>
          <p>Zoom ID : 3353743463, Password : 777</p>
        </S.AddressWrapper>
      </S.AddressContaier>
      <S.AddressContaier>
        <S.AddressWrapper>
          <h4>Church Address :</h4>
          <p>
            Peniel Gospel Fellowship Telugu Church
            <br />
            Anugraha Hall, St. Joseph Convent School Campus
            <br />
            Opp: YEZDI SHOW ROOM, Near Whitefield Police Station
            <br />
            Close to HOPE FARM METRO STATION
            <br />
            Whitefield, Bangalore-560066
          </p>
        </S.AddressWrapper>
        <S.AddressWrapper>
          <h4>Office Address :</h4>
          <p>
            Ravikumar D.
            <br />
            Orange Tree villa # 2,
            <br />
            opp. to MyFairShoppee ( provision store),
            <br />
            Adjacant to Ryan international school,
            <br />
            Brooke Fields,
            <br />
            Near Kundalahalli Signal.
            <br />
            Bangalore - 560037
          </p>
        </S.AddressWrapper>
      </S.AddressContaier>
      <S.MapContainer>
        <Col md={12}>
          <h5>Map Location</h5>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.9301576952903!2d77.74633827507654!3d12.97631888733947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae0f30e3c39fff%3A0xe54558584c087ca8!2sPGF-Whitefield!5e0!3m2!1sen!2sin!4v1709172618220!5m2!1sen!2sin"
            width="100%"
            height="300"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="PGF Map "
          ></iframe>
        </Col>
      </S.MapContainer>
      <S.SocialMediaSection></S.SocialMediaSection>
    </S.MainContainer>
  );
};

export default Footer;
