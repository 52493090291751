import styled from "styled-components";

export const MainContainer = styled.div`
  padding: 20px;
  width: 100%;
  margin: 0 auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  height: 100%;
  background: #ebebeb;
`;
export const HeadSection = styled.div`
  text-align: center;
`;
export const BodySection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  gap: 20px;

  @media (max-width: 980px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const AddressSection = styled.section`
  margin-bottom: 20px;
`;
export const EventTitle = styled.h2``;
export const SliderContainer = styled.div`
  margin-top: 10px;
`;
export const ImageContainer = styled.div`
  width: 420px;
  height: 250px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
export const SliderCloseBtn = styled.p`
  position: fixed;
  top: 30px;
  right: 30px;
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
`;

export const FullScreenImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  height: 90vh;
  border-radius: 10px;
  margin-top: 10px;
`;

export const AddressContaier = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;

  @media screen and (max-width: 950px) {
    justify-content: center;
    gap: 20px;
  }
`;
export const AddressWrapper = styled.div`
  width: 50%;
  min-width: 450px;
  min-height: 210px;
  p {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 950px) {
    width: 100%;
    min-height: auto;
    min-width: auto;
  }
`;

export const MapContainer = styled.div`
  padding: 20px 5px;
`;

export const SocialMediaSection = styled.div``;
